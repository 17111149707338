import axios from "axios"
import { identity } from "lodash"
import { toast } from "react-hot-toast"
import { currentUrl } from "../url"

export const BASE_URL = currentUrl

export const LOGIN_PATH = "/master/auth/login"
export const SEND_CODE_PATH = "/sms/send-code"
export const CONFIRM_CODE_PATH = "/sms/confirm-code"
export const REGISTER_PATH = "/master/auth/register"
export const UPDATE_PASSWORD_PATH = "/master/auth/forgot-password"

const pathsToIgnoreToast = [`${BASE_URL}/master/settings`]

const createAxiosInstance = () => {
  const client = axios.create({
    baseURL: BASE_URL,
  })

  client.interceptors.request.use((config) => {
    const token = localStorage.getItem("accessToken")

    if (token && config.headers) {
      config.headers["Authorization"] = token
    }

    return config
  })

  client.interceptors.response.use(identity, (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken")
    } else {
      if (!pathsToIgnoreToast.includes(error.request.responseURL)) {
        toast.error("Неверный номер телефона или пароль", {
          position: "bottom-right",
        })
      }
    }

    return Promise.reject(error)
  })

  return client
}

export const agent = createAxiosInstance()

export const AuthService = {
  login({ phone, password }) {
    return agent.post(
      LOGIN_PATH,
      { phone, password },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    )
  },
  getUserInfo() {
    return agent.get("/me")
  },
}

export const CustomerService = {
  patch(uid, data) {
    return agent.patch(`/users/${uid}`, data)
  },
  delete(uid) {
    return agent.delete(`/users/${uid}`)
  },
}

export const BookingsService = {
  delete(uid) {
    return agent.delete(`/master/bookings/${uid}`)
  },
}

export const CategoriesService = {
  getCategories() {
    return agent.get("/master/up-categories")
  },
  createCategory(data) {
    return agent.post("/master/up-categories", data)
  },
  updateCategory(data) {
    return agent.put(`/master/up-categories/${data.id}`, data)
  },
  deleteCategory(id) {
    return agent.delete(`/master/up-categories/${id}`)
  },
  createService(data) {
    return agent.post("/master/sub-categories/services", data)
  },
  updateService(id, data) {
    return agent.put(`/master/sub-categories/services/${id}`, data)
  },
  deleteService(id) {
    return agent.delete(`/master/sub-categories/services${id}`)
  },
}

export const EmailVerifyService = {
  verify(email) {
    if (!email) return
    return agent.post(
      "master/email/check-unique",
      { email },
      { headers: { "Content-Type": "multipart/form-data" } }
    )
  },
}

export const FeedbackService = {
  create(data) {
    return agent.post("/master/feedback", data)
  },
}