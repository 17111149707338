import { useCallback, useEffect, useState } from "react"
import { Close } from "@mui/icons-material"
import { PhoneInput } from "shared/ui/input/PhoneInput"
import { Modal } from "shared/ui/modal/Modal"
import { useGetServicesQuery } from "pages/services/slice"
import { MenuItem } from "@mui/material"
import { SelectedServiceItem } from "../table/SelectedServicesItem"
import { SelectContained } from "../../../../shared/ui/select/SelectContained"
import { Controller, useForm } from "react-hook-form"
import { useGetMastersQuery } from "../../../../pages/masters/slice"
import { useSelector } from "react-redux"
import { TableSpinner } from "../../../../shared/ui/dataTable/spinner"
import { ErrorMsg } from "../../../../shared/ui/input/ErrorMsg"
import axios from "axios"
import { currentUrl } from "../../../../url"
import { useGetOneBookingQuery } from "../../../../pages/bookings/newBookingApi"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import DatePicker from "react-datepicker"

const accessToken = localStorage.getItem("accessToken")

const user = JSON.parse(localStorage.getItem("user"))
const salonId = user?.salon_id
const startTime = user?.salon?.startWork
const endTime = user?.salon?.endWork

const formatDate = (date) => {
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0") // +1, так как месяцы начинаются с 0
  const day = newDate.getDate().toString().padStart(2, "0")

  return `${year}-${month}-${day}`
}

export const Period = ({
  startDate,
  endDate,
  onChange1,
  onChange2,
  active,
  setActive,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(startDate)
  const [selectedEndDate, setSelectedEndDate] = useState(endDate)

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date)
    onChange1(date)
  }

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date)
    onChange2(date)
  }

  const handleClose = () => {
    setActive(false)
  }
  const onSubmit = () => {
    setActive(false)
  }

  return (
    <Modal
      active={active}
      setActive={setActive}
      className='sm:max-w-[800px] max-w-[358px] w-full sm:min-h-[400px] flex justify-between flex-col '
    >
      <form
        onSubmit={onSubmit}
        className='sm:max-w-[800px] w-full  h-[35vh] sm:h-[600px]  sm:min-h-[600px] flex justify-between flex-col sm:mt-2 overflow-y-auto no-scrollbar '
      >
        <div
          className='
        flex flex-col h-full justify-between
        [&>div>label]:flex [&>div>label]:flex-col
        [&>label>span]:mb-1 text-start
        [&>label]:mt-4 sm:[&>label]:mt-6 [&>label>input]:h-12 [&>label]:self-end
        '
        >
          <div className=' flex justify-between items-center w-[280px] sm:w-auto mx-auto sm:mx-[0]'>
            <p className='title'>Выбрать период</p>

            <button onClick={handleClose} className='h-fit'>
              <Close
                sx={{ color: "gray.main", "&:hover": { color: "gray.dark" } }}
              />
            </button>
          </div>

          <div className='flex-col  sm:flex-row gap-7 sm:gap-0 sm:col-span-3 flex justify-between items-end [&>label]:w-full [&>label]:flex [&>label]:flex-col mt-4 sm:mt-6'>
            <DatePicker
              className=' flex inputOutlined mx-auto w-[300px]'
              dateFormat='dd-MM-yyyy'
              placeholderText='Период от'
              selected={selectedStartDate}
              selectsStart
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              onChange={handleStartDateChange}
            />
            <DatePicker
              className='flex inputOutlined mx-auto w-[300px] '
              dateFormat='dd-MM-yyyy'
              placeholderText='Период до'
              selected={selectedEndDate}
              selectsEnd
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              minDate={selectedStartDate}
              onChange={handleEndDateChange}
            />

            <div className='w-full   sm:w-auto sm:ml-2 mt-4 sm:mt-0'>
              <button
                type='button'
                className={
                  "opacity-100 btnFilled h-full w-full bg-[#EE6F42] text-white border-2 border-[#A4AFBD] "
                }
                onClick={() => setActive(false)}
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}