import { useState } from "react"

import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

export function BasicMenu({
  title,
  options,
  chooseOption,
  withIcon,
  className,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  function sendData(item) {
    chooseOption(item)
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={`flex items-center justify-end bg-inherit`}>
      <Button
        onClick={handleClick}
        endIcon={withIcon}
        size='small'
        sx={{
          paddingTop: "5px",
          minWidth: "30px",
          fontSize: "14px",
          color: "primary",
          boxShadow: "none",
          "&:hover": {
            bgcolor: "main",
          },
          "@media (max-width: 500px)": {
            fontSize: "12px",
          },
        }}
      >
        <span className={`font-medium ${className}`}>{title}</span>
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((item, index) => (
          <MenuItem
            key={index}
            sx={{
              fontSize: "14px",
            }}
            onClick={() => sendData(item.title)}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
