import React, { useEffect } from "react"
import { Modal } from "../../../shared/ui/modal/Modal"
import { Close } from "@mui/icons-material"
import { DeleteBtn } from "../../../shared/ui/button/DeleteBtn"
import { useFieldArray, useForm } from "react-hook-form"

export const TemplateForms = ({
  active,
  setActive,
  onSubmit,
  template,
  isLoading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      analyzes: template?.medical_tests || [
        { name: "", result: "", norm: "", unit: "" },
      ],
    },
  })

  const {
    fields: analyzesField,
    append: appendAnalyze,
    remove: removeAnalyze,
  } = useFieldArray({
    control,
    name: "analyzes",
  })

  useEffect(() => {
    setValue("name", template?.name)
    setValue("price", template?.price.slice(0, -3))
  }, [setValue, template])

  const submit = (data) => {
    onSubmit(data)
  }

  const onClose = () => {
    setActive(false)
    reset()
    reset({
      analyzes: [{ name: "", result: "", norm: "", unit: "" }],
    })
  }

  return (
    <Modal
      active={active}
      setActive={setActive}
      onClose={onClose}
      template
      className='max-w-[358px] sm:max-w-[800px] '
    >
      <div className='flex justify-between items-start mb-6 p-7'>
        <span className='title'>{"Новый шаблон"}</span>
        <button className='text-end h-fit text-gray hover:text-blue'>
          <Close onClick={onClose} />
        </button>
      </div>
      <form
        onSubmit={handleSubmit(submit)}
        className='flex flex-col items-center justify-center w-full pb-7'
      >
        <div className='flex flex-col  sm:flex-row items-center justify-between gap-5 w-full px-7'>
          <label className='flex flex-col label-base col-span-2 max-w-full w-full flex-1'>
            Название шаблона
            <input
              {...register("name", {
                required: true,
              })}
              placeholder='Введите название'
              className='inputOutlined w-full'
            />
            <p
              className={`text-red text-[12px] ${
                errors?.name ? "visible" : "invisible"
              }`}
            >
              Заполните это поле
            </p>
          </label>
          <label className='flex flex-col label-base col-span-2 sm:max-w-[225px] w-full flex-1'>
            Стоимость
            <input
              type={"float"}
              {...register("price", {
                required: "Заполните это поле",
              })}
              className='inputOutlined w-full'
            />
            <p
              className={`text-red text-[12px] ${
                errors?.price ? "visible" : "invisible"
              }`}
            >
              Заполните это поле
            </p>
          </label>
        </div>
        <div className='w-full flex flex-col items-center sm:justify-center overflow-y-hidden'>
          <div className='flex sm:items-center    flex-row ml-[25rem] sm:ml-[0] sm:justify-center gap-10 sm:gap-10 w-[700px]  max-h-[270px]  sm:w-full mt-10 sm:px-7'>
            <p className='w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]'>
              Название показателя
            </p>
            <p className='w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px] ml-[-70px] sm:ml-[0]'>
              Результат
            </p>
            <p className='w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px] ml-[-100px] sm:ml-[-50px]'>
              Норма
            </p>
            <p className='w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px] ml-[-100px] sm:ml-[-50px]'>
              Eд.измер
            </p>
          </div>
          <div className='w-[700px] ml-[25rem] sm:ml-[0] sm:w-auto flex flex-col overflow-auto no-scrollbar     gap-10 sm:gap-2 border-t border-b border-lightGray mt-4 sm:px-7 max-h-[270px]  pt-4'>
            {analyzesField &&
              analyzesField.map((field, index) => (
                <div
                  key={index}
                  className='w-full sm:w-full flex sm:gap-6 justify-between gap-7 sm:items-center sm:justify-center  '
                >
                  <div className='max-w-[214px]  sm:max-w-[170px]  h-full'>
                    <input
                      {...register(`analyzes.${index}.name`, {
                        required: "Заполните это поле",
                      })}
                      className='inputOutlined w-full'
                    />
                    <p
                      className={`text-red text-[12px] ${
                        errors?.analyzes?.[index]?.name
                          ? "visible"
                          : "invisible"
                      }`}
                    >
                      Заполните это поле
                    </p>
                  </div>
                  <div className='max-w-[214px] sm:max-w-[170px] flex flex-col h-full'>
                    <input
                      {...register(`analyzes.${index}.result`)}
                      className='inputOutlined w-full bg-[#EDEDED]'
                      disabled
                    />
                    <p
                      className={`text-red text-[12px] ${
                        errors?.analyzes?.[index]?.result
                          ? "visible"
                          : "invisible"
                      }`}
                    >
                      Заполните это поле
                    </p>
                  </div>
                  <div className='max-w-[214px]  sm:max-w-[170px] flex flex-col h-full'>
                    <input
                      {...register(`analyzes.${index}.norm`, {
                        required: "Заполните это поле",
                      })}
                      className='inputOutlined w-full'
                    />
                    <p
                      className={`text-red text-[12px] ${
                        errors?.analyzes?.[index]?.norm
                          ? "visible"
                          : "invisible"
                      }`}
                    >
                      Заполните это поле
                    </p>
                  </div>
                  <div className='max-w-[104px]  sm:max-w-[80px] flex flex-col h-full'>
                    <input
                      {...register(`analyzes.${index}.unit`, {
                        required: "Заполните это поле",
                      })}
                      className='inputOutlined w-full'
                    />
                    <p
                      className={`text-red text-[12px] whitespace-nowrap ${
                        errors?.analyzes?.[index]?.unit
                          ? "visible"
                          : "invisible"
                      }`}
                    >
                      Заполните это поле
                    </p>
                  </div>

                  <div className='ml-8 my-auto '>
                    <DeleteBtn
                      disabled={analyzesField.length === 1}
                      onClick={() => removeAnalyze(index)}
                    />
                    <p
                      className={`text-red text-[12px] ${
                        errors?.delete ? "visible" : "invisible"
                      }`}
                    >
                      Заполните
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className='flex items-center w-full sm:w-auto justify-center mt-6'>
          <button
            onClick={() =>
              appendAnalyze({ name: "", result: "", norm: "", unit: "" })
            }
            type='button'
            className='py-3 px-4 border border-[#A4AFBD] text-[#737C86] flex items-center sm:justify-between rounded-[8px] hover:bg-[#EE6F42] hover:text-white transition-all max-w-[294px] w-full  justify-center  sm:w-auto'
          >
            Добавить показатель
          </button>
        </div>
        <div className='w-full flex justify-end items-end gap-6 mt-6 px-7'>
          <button
            onClick={onClose}
            type='button'
            className='btnOutlined w-[136px]'
          >
            Отмена
          </button>
          <button
            className={
              isLoading
                ? "opacity-50 btnFilled w-[136px]"
                : "opacity-100 btnFilled w-[136px]"
            }
          >
            Сохранить
          </button>
        </div>
      </form>
    </Modal>
  )
}

