import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReauth, getApiConfig } from "shared/api"

const { BASE_URL, prepareHeaders } = getApiConfig()
const SERVICES_PATH = "master/services"

function transformGetServicesResponse({ data }) {
  return data.map(
    ({
      id,
      name,
      price,
      duration,
      category_service,
      sub_category_service,
    }) => ({
      id,
      name,
      price,
      duration,
      category: {
        id: category_service?.id ?? "",
        name: category_service?.name ?? "",
      },
      subCategory: {
        id: sub_category_service?.id ?? "",
        name: sub_category_service?.name ?? "",
      },
    })
  )
}

export const servicesApi = createApi({
  reducerPath: "servicesApi",
  tagTypes: ["Services"],
  baseQuery: baseQueryWithReauth({baseUrl: BASE_URL, prepareHeaders}),
  endpoints: (builder) => ({
    // get all

    getServices: builder.query({
      query: () => `/${SERVICES_PATH}`,
      providesTags: (someData) =>
        someData?.data
          ? [
              ...someData?.data?.map(({ id }) => ({ type: "Services", id })),
              { type: "Services", id: "LIST" },
            ]
          : [{ type: "Services", id: "LIST" }],
      transformResponse: transformGetServicesResponse
    }),
    getServiceFilter:builder.query({
      query: () => `/${SERVICES_PATH}`,
    }),
    // post
    postService: builder.mutation({
      query: ({service, category, subCategory}) => ({
        url: `/${SERVICES_PATH}`,
        method: "POST",
        // SET CORRECT SALON_ID !!!
        body: { ...service, sub_category_service_id: subCategory.id, category_service_id: category.id },
      }),
      invalidatesTags: [{ type: "Services", id: "LIST" }],
    }),
    // put
    updateService: builder.mutation({
      query: ({ service, category, subCategory }) => ({
        url: `/${SERVICES_PATH}/${service.id}`,
        method: 'PUT',
        body: { ...service }
      }),
      invalidatesTags: [{ type: "Services", id: "LIST" }]
    }),
    // delete
    removeService: builder.mutation({
      query: ({id}) => ({
        url: `/${SERVICES_PATH}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Services", id: "LIST" }],
    }),
  }),
})

export const {
  useGetServiceFilterQuery,
  useGetServicesQuery,
  usePostServiceMutation,
  useUpdateServiceMutation,
  useRemoveServiceMutation
} = servicesApi
