import React, { useState } from "react"
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"
import { ErrorMsg } from "shared/ui/input/ErrorMsg"

export const PasswordInput = React.forwardRef(
  (
    {
      label,
      value,
      name,
      handleValue,
      error,
      placeholder,
      autoFocus,
      className,
      disabled,
      onKeyDown,
      id,
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false)
    function toggleVisibility() {
      setVisible(!visible)
    }

    return (
      <>
        <label
          htmlFor='confPass'
          className={`${className} label-base flex flex-col relative`}
        >
          {label}
          <input
            id={id}
            ref={ref}
            autoFocus={autoFocus}
            disabled={disabled}
            color='primary'
            value={value}
            onChange={handleValue}
            placeholder={placeholder}
            name={name}
            onKeyDown={onKeyDown}
            className={`${
              error && "border-red-500 focus:border-red-500"
            } inputOutlined relative mt-1 pr-12`}
            type={visible ? "text" : "password"}
          />
          <div
            onClick={toggleVisibility}
            className='w-fit absolute right-3 top-[47%] cursor-pointer'
          >
            {visible ? (
              <VisibilityOutlined sx={{ width: "18px" }} />
            ) : (
              <VisibilityOffOutlined sx={{ width: "18px" }} />
            )}
          </div>
          {error && <ErrorMsg title={error} />}
        </label>
      </>
    )
  }
)
