export const INITIAL_STATE = {
  pages: {
    bookings: {
      data: "",
    },
    notifications: {
      data: "",
    },
    customers: {
      data: "",
    },
    categories: {
      data: "",
    },
  },
  auth: {
    accessToken: "",
    refreshToken: "",
    user: {},
    role: "", // @DEBUG role maybe exists in userObject
    checkingIsAuth: false,
  },
}