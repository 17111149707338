import { Logo } from "./Logo"

export function Banner({ title }) {
  return (
    <div className='w-full h-full justify-center items-center flex flex-col -translate-y-14'>
      <div className='flex items-center mb-14'>
        <Logo />
        <p className='font-black text-blue text-6xl leadin-[83px]'>Navbat</p>
      </div>

      <p className='-translate-y-10 text-2xl max-w-sm text-center'>{title}</p>
    </div>
  )
}