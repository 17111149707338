import { DeleteOutlineOutlined } from "@mui/icons-material"
import { Button } from "@mui/material"

// const titles = [
//   "Услуга",
//   "Мастер",
//   "Цена",
//   "Продолжительность",
//   "",
// ]

export const SelectedServiceItem = ({
  services,
  handleDeleteMaster,
  servicesFromBack,
  id,
  emptyServicesError,
}) => {
  function formatDuration(duration) {
    const [hours, minutes, seconds] = duration.split(":").map(Number)

    // Преобразуем часы и минуты в двузначные числа
    const formattedHours = hours.toString().padStart(2, "0")
    const formattedMinutes = minutes.toString().padStart(2, "0")

    return `${formattedHours}:${formattedMinutes}`
  }

  function insertSoftHyphens(text, maxLen = 15) {
    return text
      .split(" ")
      .map((word) => {
        if (word.length > maxLen) {
          let parts = []
          for (let i = 0; i < word.length; i += maxLen) {
            parts.push(word.substring(i, i + maxLen))
          }
          return parts.join("&shy;")
        }
        return word
      })
      .join(" ")
  }
  return (
    <>
      {/*<div*/}
      {/*  className={`sm:grid hidden gap-3 grid grid-cols-5 py-4 text-lg [&>p]:pr-2 text-center`}*/}
      {/*>*/}
      {/*  {titles.map((item) => (*/}
      {/*    <p*/}
      {/*      key={item}*/}
      {/*      className="last:text-end whitespace-nowrap overflow-hidden w-full text-sm label-base"*/}
      {/*    >*/}
      {/*      {item}*/}
      {/*    </p>*/}
      {/*  ))}*/}
      {/*</div>*/}
      {id &&
        servicesFromBack?.map((service, index) => (
          <div
            key={`${service?.id}-${service?.service_name}`}
            className={`p-1 text-sm items-center text-center tableRows sm:grid sm:gap-3   gap-3 grid grid-cols-5 py-4 text-lg [&>p]:pr-2`}
          >
            <>
              <span className='truncate '>{service?.name}</span>
              <span>
                {service?.master_name} {service?.master_surname}{" "}
              </span>
              <span>{service?.price}</span>
              <span>{formatDuration(service?.duration)}</span>

              {/*<Button*/}
              {/*  variant="text"*/}
              {/*  onClick={() => {*/}
              {/*    handleDeleteMaster(index)*/}
              {/*  }}*/}
              {/*  className="tableBtns h-full flex items-center w-fit justify-self-end">*/}
              {/*  <DeleteOutlineOutlined style={{ color: "gray", fontSize: 21 }} />*/}
              {/*</Button>*/}
            </>
            <span className='tableRowLine flex'></span>
          </div>
        ))}
      {services.map((service, index) => (
        <div
          key={`${service?.id}-${service?.service_name}`}
          className={`p-1 text-sm items-center text-center tableRows  sm:grid sm:gap-3 flex flex-row gap-40   border-b-2 border-[#EDEDED]  sm:border-none  sm:grid-cols-5 py-4 text-lg [&>p]:pr-2`}
        >
          <>
            <span
              className='w-[2%]  sm:w-auto  hyphens-manual sm:text-left sm:ml-[10px]  ml-[50]'
              dangerouslySetInnerHTML={{
                __html: insertSoftHyphens(service?.service_name),
              }}
            ></span>
            <span>
              {service?.master_name} {service?.master_surname}
            </span>
            <span className='ml-[-30px] sm:ml-[0]'>{service?.price}</span>
            <span>{formatDuration(service?.duration)}</span>
            {!id && (
              <Button
                variant='text'
                onClick={() => {
                  handleDeleteMaster(index)
                }}
                className='tableBtns  flex items-center w-fit justify-self-end'
              >
                <DeleteOutlineOutlined
                  style={{ color: "gray", fontSize: 21 }}
                />
              </Button>
            )}
          </>
          <span className='tableRowLine flex'></span>
        </div>
      ))}
    </>
  )
}
