import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"

export default function Modal({ open, setOpen, title }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0  bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 top-20 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative max-w-sm transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-8 md:max-w-2xl xl:max-w-5xl'>
                <div className='absolute right-0 top-0 block pr-4 pt-4'>
                  <button
                    type='button'
                    className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
                    onClick={() => setOpen(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div className='sm:flex sm:items-start'>
                  <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='mb-5 text-left text-lg font-semibold leading-6 text-gray-900 sm:text-center'
                    >
                      {title}
                    </Dialog.Title>
                    <div className='mt-2'>
                      {title === "Пользовательское соглашение" ? (
                        <div className='flex flex-col gap-2 text-left'>
                          <p>
                            Пожалуйста, внимательно ознакомьтесь с этим
                            Пользовательским соглашением, поскольку оно
                            устанавливает правила и условия использования нашего
                            сервиса. Ваше использование нашего сервиса означает
                            ваше согласие с этим соглашением.
                          </p>
                          <h2>1. Регистрация и аккаунт</h2>
                          <p>
                            Для доступа к некоторым функциям нашего сервиса вы
                            можете быть обязаны зарегистрироваться и создать
                            аккаунт. При регистрации вы обязаны предоставить
                            точные и полные данные о себе и поддерживать их в
                            актуальном состоянии. Вы несете ответственность за
                            конфиденциальность вашего пароля и аккаунта, а также
                            за все действия, совершенные с использованием вашего
                            аккаунта.
                          </p>
                          <h2>2. Права интеллектуальной собственности</h2>
                          <p>
                            Все материалы на нашем веб-сайте и сервисе, включая
                            текст, изображения, графику, логотипы, аудио и
                            видео, являются объектами интеллектуальной
                            собственности и защищены законами об авторских
                            правах и другими применимыми законодательными
                            актами. Вы несете ответственность за соблюдение этих
                            прав и запрещено воспроизведение, распространение,
                            модификация или использование этих материалов без
                            нашего предварительного письменного согласия.
                          </p>
                          <h2>
                            3. Вы обязуетесь использовать наш веб-сайт и сервис
                            только в законных целях и согласно этому соглашению.
                            Запрещено:
                          </h2>
                          <ul className='list-disc pl-5'>
                            <li>
                              <p>
                                Размещать незаконный, вредоносный,
                                оскорбительный, клеветнический,
                                дискриминационный или непристойный контент.
                              </p>
                            </li>
                            <li>
                              <p>
                                Нарушать права других пользователей или третьих
                                лиц.
                              </p>
                            </li>
                            <li>
                              <p>
                                Вмешиваться в работу нашего веб-сайта, пытаться
                                получить несанкционированный доступ или
                                использовать автоматизированные средства для
                                сбора информации.
                              </p>
                            </li>
                            <li>
                              <p>
                                Размещать ложную или обманывающую информацию.
                              </p>
                            </li>
                          </ul>
                          <h2>4. Отказ от ответственности</h2>
                          <p>
                            Мы предоставляем наш веб-сайт и сервис &quot;как
                            есть&quot; и не несем ответственности за возможные
                            ошибки, пропуски или недостоверность информации, а
                            также за любые убытки или повреждения, возникшие в
                            результате использования нашего веб-сайта и сервиса.
                            Мы также не несем ответственности за содержание
                            сторонних веб-сайтов, на которые могут быть
                            размещены ссылки на нашем веб-сайте.
                          </p>
                          <h2>5. Изменения в соглашении</h2>
                          <p>
                            Мы оставляем за собой право вносить изменения в это
                            Пользовательское соглашение по своему усмотрению.
                            Любые изменения будут размещены на нашем веб-сайте,
                            и вы будете уведомлены об этом при следующем
                            посещении. Продолжение использования нашего
                            веб-сайта после таких изменений означает ваше
                            согласие с обновленным соглашением.
                          </p>
                          <h2>6. Прекращение соглашения</h2>
                          <p>
                            Мы оставляем за собой право приостановить или
                            прекратить ваш доступ к нашему сервису по своему
                            усмотрению, в случае нарушения вами этого
                            Пользовательского соглашения или применимого
                            законодательства.
                          </p>
                          <p>
                            Если у вас есть вопросы или требуется дополнительная
                            информация о нашем Пользовательском соглашении,
                            пожалуйста, свяжитесь с нами воспользовавшись формой
                            обратной связи на нашем сайте.
                          </p>
                        </div>
                      ) : (
                        <div className='flex flex-col gap-2 text-left'>
                          <p>
                            Эта Политика конфиденциальности описывает, как мы
                            собираем, используем, храним и раскрываем
                            информацию, полученную от пользователей нашего
                            веб-сайта и сервиса navbat.tj. Мы стремимся защитить
                            вашу конфиденциальность и обеспечить безопасность
                            вашей личной информации. Пожалуйста, внимательно
                            ознакомьтесь с этой политикой, чтобы понять, как мы
                            обрабатываем и защищаем ваши данные.
                          </p>
                          <h2>1. Сбор информации</h2>
                          <p>
                            Мы можем собирать различные типы информации, включая
                            персональные данные, которые вы предоставляете нам
                            добровольно при регистрации на нашем веб-сайте,
                            оформлении заказов, заполнении форм или
                            взаимодействии с нашими услугами. Эта информация
                            может включать ваше имя, адрес электронной почты,
                            контактные данные, информацию о платежах и другие
                            детали, необходимые для предоставления вам наших
                            услуг.
                          </p>
                          <h2>2. Использование информации</h2>
                          <p>
                            Мы используем предоставленную вами информацию для
                            обеспечения функционирования нашего веб-сайта,
                            предоставления вам запрошенных услуг, улучшения
                            нашего обслуживания и связи с вами. Мы также можем
                            использовать информацию для анализа и сбора
                            статистических данных с целью улучшения работы
                            нашего веб-сайта и адаптации его к потребностям
                            пользователей.
                          </p>
                          <h2>3. Раскрытие информации третьим лицам</h2>
                          <p>
                            Мы не продаем, не обмениваем и не передаем вашу
                            личную информацию третьим лицам без вашего согласия,
                            за исключением случаев, предусмотренных
                            законодательством или когда это необходимо для
                            предоставления вам запрошенных услуг (например,
                            обработка платежей или доставка товаров).
                          </p>
                          <h2>4. Безопасность</h2>
                          <p>
                            Мы принимаем все необходимые меры для обеспечения
                            безопасности вашей личной информации. Мы используем
                            технические и организационные меры для защиты данных
                            от несанкционированного доступа, изменения,
                            раскрытия или уничтожения. Однако, пожалуйста,
                            имейте в виду, что ни один метод передачи данных
                            через Интернет и ни один метод электронного хранения
                            не являются 100% безопасными.
                          </p>
                          <h2>5. Ссылки на сторонние сайты</h2>
                          <p>
                            Наш веб-сайт может содержать ссылки на сторонние
                            сайты, которые не контролируются нами. Мы не несем
                            ответственности за политики конфиденциальности или
                            содержание этих сторонних сайтов. Рекомендуется
                            ознакомиться с политиками конфиденциальности этих
                            сторонних сайтов перед предоставлением им своей
                            личной информации.
                          </p>
                          <h2>
                            6. Внесение изменений в Политику конфиденциальности
                          </h2>
                          <p>
                            Мы можем время от времени вносить изменения в эту
                            Политику конфиденциальности. Любые изменения будут
                            размещены на этой странице, и вы будете уведомлены
                            об этом при следующем посещении нашего веб-сайта.
                          </p>
                          <h2>7. Контакты</h2>
                          <p>
                            Если у вас есть вопросы или требуется дополнительная
                            информация о нашей Политике конфиденциальности,
                            пожалуйста, свяжитесь с нами воспользовавшись формой
                            обратной связи на нашем сайте.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
