import { Login } from "./login/index"

export const AuthPage = () => {
  return (
    <>
      <div className='w-full h-full  flex '>
        <Login />
      </div>
    </>
  )
}