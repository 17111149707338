import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  timeslotSteps: 30,
}

export const bookingsCalendarSlice = createSlice({
  name: "bookingsCalendar",
  initialState,
  reducers: {
    editTimeslotSteps: (state, { payload }) => {
      state.timeslotSteps = payload
    },
  },
})

export const { editTimeslotSteps } = bookingsCalendarSlice.actions

export default bookingsCalendarSlice.reducer