import React, { useState } from "react"
import { EditBtn } from "../../../shared/ui/button/EditBtn"
import { DeleteBtn } from "../../../shared/ui/button/DeleteBtn"
import { EditBooking } from "../../../features/bookings/ui/EditBooking"
import { RemoveBooking } from "../../../features/bookings/ui/RemoveBooking"
import { useNavigate } from "react-router-dom"
import { RemoveAnalyze } from "../../../pages/laboratory/analyzes/RemoveAnalyze"
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined"

const user = JSON.parse(localStorage.getItem("user"))

export const AnalyzesRows = ({ analyze, titles }) => {
  const [activeRemove, setActiveRemove] = useState(false)
  const navigate = useNavigate()

  // const formatter = new Intl.DateTimeFormat("ru", {
  //   weekday: "short",
  //   day: "numeric",
  //   month: "short",
  //   hour: "numeric",
  //   minute: "numeric",
  // });

  function edit() {
    navigate(`/analyzes/${analyze?.id}`)
  }

  function remove() {
    setActiveRemove(true)
  }

  return (
    <>
      <>
        {" "}
        {/* <div className="grid grid-cols-8 gap-4 items-center py-2 border-b"> */}
        <div className='sm:grid  grid-cols-8 hidden   items-center   min-h-[40px] gap-x-4 '>
          <p>{analyze?.id}</p>
          <p>
            {analyze?.name} {analyze?.surname}
          </p>
          <p>{analyze?.visit}</p>
          <p>{analyze?.phone} </p>
          <p>{analyze?.total_sum}</p>
          <span
            className={`rounded-[12px] text-center 
          ${analyze?.status === "Не готов" ? "text-[#991B1B] bg-[#FEE2E2]" : "bg-[#D1FAE5] text-[#065F46]"}`}
          >
            {analyze?.status}
          </span>
          <span
            className={`rounded-[12px] text-center  
          ${analyze?.total_sum === analyze?.balance ? "text-[#991B1B] bg-[#FEE2E2]" : analyze?.balance === "0.00" ? "bg-[#D1FAE5] text-[#065F46]" : "bg-[#FEF3C7] text-[#92400E]"}`}
          >
            {analyze?.total_sum === analyze?.balance
              ? "Не оплачен"
              : analyze?.balance === "0.00"
                ? "Оплачен"
                : "Частично оплачен"}
          </span>
          <div
            onClick={(e) => e.stopPropagation()}
            className='tableBtns h-full flex items-center w-fit  justify-self-end'
          >
            <EditBtn onClick={edit} />
            <DeleteBtn onClick={remove} />
          </div>
        </div>
        <div className='sm:hidden '>
          <div className='bg-white p-3 rounded-lg mb-5 last:mb-0 flex flex-col sm:flex-row justify-between gap-1 shadow-md sm:shadow-none'>
            <div className='flex justify-between items-center'>
              <p className='font-bold text-gray-900'>ID {analyze.id}</p>
              <span
                className={`rounded-[12px] text-center  px-2 py-1 text-sm ${analyze?.status === "Не готов" ? "text-[#991B1B] bg-[#FEE2E2]" : "bg-[#D1FAE5] text-[#065F46]"}`}
              >
                {analyze?.status}
              </span>
              <div className='flex'>
                <p>
                  <EditBtn onClick={edit} />
                  <DeleteBtn onClick={remove} />
                </p>
              </div>
            </div>
            <p className='text-[#A4AFBD]'>{analyze.visit}</p>
            <p className='font-medium text-[#737C86]'>
              {analyze.name} {analyze?.surname}
            </p>
            <p className='text-[#A4AFBD] '>{analyze.phone}</p>
            <div className='flex flex-row justify-between'>
              <p className='font-bold text-[#1E3148]'>
                {analyze.total_sum} TJS
              </p>
              <span
                className={`rounded-[12px] text-center px-10 py-1 text-sm ${analyze?.total_sum === analyze?.balance ? "text-[#991B1B] bg-[#FEE2E2]" : analyze?.balance === "0.00" ? "bg-[#D1FAE5] text-[#065F46]" : "bg-[#FEF3C7] text-[#92400E]"}`}
              >
                {analyze?.total_sum === analyze?.balance
                  ? "Не оплачен"
                  : analyze?.balance === "0.00"
                    ? "Оплачен"
                    : "Частично оплачен"}
              </span>
            </div>
          </div>
        </div>
      </>

      {activeRemove && (
        <RemoveAnalyze
          analyze={analyze}
          active={activeRemove}
          setActive={setActiveRemove}
        />
      )}
    </>
  )
}