import { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import "./style.css"

export function Portal({ children }) {
  const [container] = useState(() => document.createElement("div"))

  useEffect(() => {
    document.body.appendChild(container)

    return () => {
      document.body.removeChild(container)
    }
  }, [])

  return ReactDOM.createPortal(children, container)
}

export function Modal({
  active,
  setActive,
  children,
  className,
  onClose,
  wrapClassName,
  template,
}) {
  if (!active) return null

  return (
    <Portal>
      <div
        className={`${wrapClassName}
        w-[100vw] h-[100vh] bg-[rgb(0,0,0)]/20 fixed left-0 top-0 right-0 bottom-0
        flex justify-center items-center z-[800]
      `}
        onClick={onClose}
      >
        <div
          className={`${className} w-full bg-white rounded-xl ${
            template ? "" : "p-7"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </Portal>
  )
}
