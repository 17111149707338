import { isRejectedWithValue } from "@reduxjs/toolkit"

const redirectToLogin = () => {
  window.location.href = "/login"
}

export const rtkQueryErrorMiddleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const status = action.payload?.status
    if (status === 401) {
      redirectToLogin()
    }
  }

  return next(action)
}
