import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { hashUrl } from "url"

const baseUrl = hashUrl


export const salonOwnerHashApi = createApi({
  reducerPath: "salonOwnerHashApi",
  // baseQuery: fetchBaseQuery({ baseUrl }),
    baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
    
      headers.set('Navbat-key', `kEX9oFXFkv0GGiOdPO55xgcZ4pAErUzNOoej3kwr`); 
   
      return headers;
    },
  }),
  endpoints: (builder) => ({
     getByHash: builder.query({
      query: (hash) => `client/v1/salons/s/${hash}`,
    }),
   
 postUpdateCallRequest: builder.mutation({
      query: ({ hash, callRequested }) => ({
        url: `client/v1/salons/${hash}/update-call-request`,
        method: "POST",
        params: { call_requested: callRequested },
      }),
    }),
  }),
})


export const { useGetByHashQuery, usePostUpdateCallRequestMutation } = salonOwnerHashApi;
