const { createContext, useContext, useState } = require("react")
const { getRootStore } = require("store")

const StoreContext = createContext(getRootStore())

export const GlobalStoreProvider = ({ store, children }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export const useStore = () => useContext(StoreContext)

export const useForceRerender = () => {
  const [state, setState] = useState(false)

  const forceRerender = () => {
    setState(!state)
  }

  return forceRerender
}