import { fetchBaseQuery, retry } from "@reduxjs/toolkit/dist/query"
import { currentUrl } from "../../url"

export const BASE_URL = currentUrl

export function getApiConfig() {
  // const BASE_URL = "//127.0.0.1:8000/api/v1/"

  function prepareHeaders(headers) {
    const accessToken = localStorage.getItem("accessToken")
    if (accessToken) {
      headers.set("Authorization", `${accessToken}`)
    }
    headers.set("Content-Type", "application/json")
    headers.set("Accept", "application/json")
    return headers
  }

  return {
    BASE_URL,
    accessToken: localStorage.getItem("accessToken"),
    prepareHeaders,
  }
}

export function baseQueryWithReauth({baseUrl, prepareHeaders, retriesCount = 3}) {
  try {
    return retry(
      fetchBaseQuery({
        baseUrl,
        prepareHeaders,
      }),
      {maxRetries: retriesCount}
    )
  } catch (error) {
    if (error.status === 401) {
      localStorage.removeItem('accessToken')
      window.location.reload()
      return error
    }
  }
}
