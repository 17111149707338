import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { baseQueryWithReauth, getApiConfig } from "shared/api"

const { BASE_URL, prepareHeaders } = getApiConfig()
const WORKTIME_PATH = "master/work-times"
const DAY_WORKTIME_TYPE = "day"
const WEEK_WORKTIME_TYPE = "week"

export const worktimeApi = createApi({
  reducerPath: "worktimeApi",
  tagTypes: ["Worktime"],
  baseQuery: baseQueryWithReauth({ baseUrl: BASE_URL, prepareHeaders }),
  endpoints: (builder) => ({
    getWorktime: builder.query({
      query: ({ model_type, model_id, start_date, end_date }) =>
        `/${WORKTIME_PATH}?model_type=${model_type}&model_id=${model_id}&start_date=${start_date}&end_date=${end_date}`,
      providesTags: ({ data }) => {
        if (data) {
          return [
            ...data?.map(({ id }) => ({ type: "Worktime", id })),
            { type: "Worktime", id: "LIST" },
          ]
        }
        return [{ type: "Worktime", id: "LIST" }]
      },
      transformResponse: ({ data }) => data,
    }),
    // day worktime api
    postDayWorktime: builder.mutation({
      query: ({ model_type, model_id, day, work_time, lunch }) => ({
        url: `/${WORKTIME_PATH}`,
        method: "POST",
        body: {
          work_time_type: DAY_WORKTIME_TYPE,
          model_type,
          model_id,
          day,
          work_time,
          lunch,
        },
      }),
      invalidatesTags: [{ type: "Worktime", id: "LIST" }],
    }),
    updateDayWorktime: builder.mutation({
      query: ({ id, model_type, model_id, day, work_time, lunch }) => ({
        url: `/${WORKTIME_PATH}/${id}`,
        method: "PUT",
        body: {
          work_time_type: DAY_WORKTIME_TYPE,
          model_type,
          model_id,
          day,
          work_time,
          lunch,
        },
      }),
      invalidatesTags: [{ type: "Worktime", id: "LIST" }],
    }),
    // week worktime api
    addWeekWorktime: builder.mutation({
      query: ({ model_id, model_type, day, work_time, ...weekdays }) => ({
        url: `/${WORKTIME_PATH}`,
        method: "POST",
        body: {
          work_time_type: WEEK_WORKTIME_TYPE,
          model_type,
          model_id,
          ...weekdays,
        },
      }),
      invalidatesTags: [{ type: "Worktime", id: "LIST" }],
    }),
    updateWeekWorktime: builder.mutation({
      query: ({ id, model_id, model_type, day, work_time, ...weekdays }) => ({
        url: `/${WORKTIME_PATH}/${id}`,
        method: "PUT",
        body: {
          work_time_type: WEEK_WORKTIME_TYPE,
          model_type,
          model_id,
          ...weekdays,
        },
      }),
      invalidatesTags: [{ type: "Worktime", id: "LIST" }],
    }),
  }),
})

export const {
  useLazyGetWorktimeQuery,

  usePostDayWorktimeMutation,
  useUpdateDayWorktimeMutation,

  useAddWeekWorktimeMutation,
  useUpdateWeekWorktimeMutation
} = worktimeApi