import { Modal } from "@mui/material"
import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"
import React, { useEffect, useState } from "react"
import { useSendPdfToSmsMutation } from "../analyzesApi"
import { toast } from "react-hot-toast"
import { useGetCitiesQuery } from "../../../settings/settingsApi"

const user = JSON.parse(localStorage.getItem("user"))

function getCurrentDateTime() {
  const now = new Date()

  const day = String(now.getDate()).padStart(2, "0")
  const month = String(now.getMonth() + 1).padStart(2, "0") // Месяцы начинаются с 0
  const year = now.getFullYear()

  const hours = String(now.getHours()).padStart(2, "0")
  const minutes = String(now.getMinutes()).padStart(2, "0")

  return `${day}.${month}.${year} ${hours}:${minutes}`
}


export const AllTestsModal = ({open, setOpen, data}) => {
  const [cityName, setCityName] = useState('')
  const {data: citiesData} = useGetCitiesQuery()
  const [sendSms, {isLoading:smsLoading}] = useSendPdfToSmsMutation()
  useEffect(() => {
    const cityName = citiesData?.find(item => item.id === user?.salon?.city_id)
    setCityName(cityName?.name)
  }, [citiesData])
  const openPdfInNewTab = (pdf) => {
    const pdfBlob = pdf.output('blob');

    const pdfUrl = URL.createObjectURL(pdfBlob);

    window.open(pdfUrl, '_blank');
  };

  const sendPdfToSms = async (pdf) => {
    const pdfBlob = pdf.output('blob');

    const formData = new FormData()
    formData.append('file', pdfBlob, 'generated.pdf')
    try {
      await sendSms(formData).unwrap()
      toast.success('Смс успешно отправлено клиенту', {position: 'top-right'})
    } catch (err) {
      console.log(err)
      toast.error('Ошибка при отправке смс', {position: 'top-right'})
    }
  }
  const generatePdf = async (str) => {
    const content = document.getElementById("tests_pdf");
    const canvas = await html2canvas(content, {
      scale: 2, // Увеличить качество изображения
      useCORS: true, // Если изображения загружаются с других доменов
    });
    const imgData = canvas.toDataURL("image/png");
    const pdfWidth = canvas.height * 2; // Уменьшение размера изображения
    const pdfHeight = canvas.height;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [canvas.width, canvas.height]
    });
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    if(str === 'sms') {
      await sendPdfToSms(pdf)
    } else {
      openPdfInNewTab(pdf);
    }
  }

    return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="max-w-[358px] w-full sm:max-w-[900px] rounded-lg no-scrollbar h-full bg-white mx-auto mt-4 pb-10 overflow-y-auto text-black">
        <div id={"tests_pdf"} className="min-h-[900px] text-black w-full pt-12 px-6 sm:px-12 pb-5 bg-white overflow-y-auto">
          <div className="text-center sm:text-start text-black font-medium text-[20px]">
            <p>"Дента"</p>
            <p>{`г. ${cityName}, ул. ${user?.salon?.street} ${user?.salon?.building}`}</p>
            <p>{`тел: +992 ${user?.salon?.phone}`}</p>
            <p>{getCurrentDateTime()}</p>
          </div>
          <div className="flex sm:flex-row flex-col sm:items-center justify-start w-full  mt-6 gap-4 sm:gap-[200px]">
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="text-[#737C86] text-[14px] font-medium">ID анализа</p>
              <p>{data?.report?.id}</p>
            </div>
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="text-[#737C86] text-[14px] font-medium">ФИО</p>
              <p>{data?.report?.surname} {data?.report?.name} {data?.report?.patronymic || ''}</p>
            </div>
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="text-[#737C86] text-[14px] font-medium">Дата рождения</p>
              <p>{data?.report.birthday}</p>
            </div>
          </div>
          {data?.templates.map(template => <div >
            <h1 className="text-[32px] font-medium text-center mt-8 overflow-hidden">{template?.name}</h1>
            <div className="overflow-x-auto no-scrollbar">
            <div
              className=" flex sm:items-center justify-start gap-10 w-[800px] mt-10 px-7 overflow-x-auto sm:overflow-hidden sm:border border-b sm:rounded-t-[8px] border-lightGray py-3">
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[14px]">Название показателя</p>
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[14px]">Результат</p>
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[14px]">Норма</p>
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[14px]">Eд.измер</p>
            </div>
            <div
              className="w-[800px] flex flex-col gap-2 sm:border-x border-b border-lightGray  overflow-y-auto">
              {template.items.map(item =>
                <div key={item.id} className="w-full flex items-center border-b border-lightGray justify-between py-4">

                  <p className={`text-[16px] px-7 w-[26%]`}>{item.name}</p>
                  <p className={`text-[16px] px-7 w-[25%]`}>{item.result}</p>
                  <p className={`text-[16px] px-7 w-[25%]`}>{item.norm}</p>
                  <p className={`text-[16px] px-7 w-[25%]`}>{item.unit}</p>
                  <p></p>

                </div>)}
            </div>
            </div>
          </div>)}

        </div>
        <div className='flex items-center justify-center gap-4'>
          <button disabled={smsLoading} className={smsLoading ? "opacity-50 btnFilled" : "btnFilled"} type={'button'} onClick={() => generatePdf('view')}>Распечатать</button>
          <button
            disabled={smsLoading}
            type={'button'}
            onClick={() => generatePdf('sms')}
            className={smsLoading ? "opacity-50 btnOutlined px-4" : "btnOutlined px-4"}>
            Отправить смс
          </button>
        </div>
      </div>
    </Modal>
  )
}

