import { has, truncate } from "lodash"
import React, { useState } from "react"
import {
  useGetBookingByHashQuery,
  useGetByHashQuery,
  useGetOwnerDataByHashQuery,
  usePostUpdateCallRequestMutation,
} from "./salonOwnerSlice"
import { useParams } from "react-router-dom"
import logo from "../../assets/logo.svg"
import banner from "../../assets/salonsOwner.png"
import { TableSpinner } from "shared/ui/dataTable/spinner"

const SalonsOwner = () => {
  const [buttonState, setButtonState] = useState("default")

  const [phoneNumber, setPhoneNumber] = useState("")

  const { hash } = useParams()

  const { data: hashData, isLoading ,isError} = useGetByHashQuery(hash)
  const [updateCallRequest] = usePostUpdateCallRequestMutation()

  const salonsData = hashData?.data

  console.log(hashData?.data, "hashData")

  const handleClick = async () => {
    setButtonState("loading")

    if (salonsData?.salon?.call_requested === 1) {
      setTimeout(() => {
        setPhoneNumber(salonsData?.client?.phone)
        setButtonState("number")
      }, 1000)
    } else {
      try {
        const response = await updateCallRequest({
          hash: hash,
          callRequested: 1,
        }).unwrap()

        if (response) {
          setPhoneNumber(salonsData?.client?.phone)
          setButtonState("number")
        } else {
          console.error("Error: Call request failed")
          setButtonState("default")
        }
      } catch (error) {
        console.error("Network error:", error)
        setButtonState("default")
      }
    }
  }

  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <TableSpinner />
      </div>
    )
  }

  if (isError) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <p>Произошла ошибка при загрузке данных.</p>
      </div>
    )
  }

  return (
    <form className=' w-full flex flex-col items-center bigMobile:gap-2 !overflow-y-auto no-scrollbar bigMobile:h-[100vh] h-full bg-[#FFFFFF] bigMobile:overflow-hidden mobile:mb-4'>
      <div className='text-center  w-full bigMobile:h-[55px] mobile:h-[65px] bg-[#1E3148] text-white bigMobile:p-2 py-4   '>
        <img
          src={logo}
          alt=''
          className='flex justify-center items-center mx-auto'
        />
      </div>
      <div className='bg-white p-2 py-4  flex flex-col gap-6  mb-4 w-full  max-w-[358px]  '>
        <div className='flex flex-col justify-between gap-6'>
          <div className='flex  bg-[#F6FAFF] h-[52px] justify-between  mx-auto w-full p-2 rounded-lg '>
            <div>
              <p className='text-gray-600 text-small font-normal text-[#A4AFBD] '>
                Имя отправителя
              </p>
              <p className='font-medium text-medium '>
                {salonsData?.client?.name + " " + salonsData?.client?.surname}
              </p>
            </div>
            <div className='text-end'>
              <p className='text-gray-600 text-small font-normal text-[#A4AFBD] '>
                Время заявки
              </p>
              <p className='font-medium text-medium'>
                {salonsData?.salon?.created_at}
              </p>
            </div>
          </div>
          <div className='flex  bg-[#F6FAFF] h-[52px] justify-between  mx-auto w-full p-2 rounded-lg '>
            <div>
              <p className='text-gray-600 text-small font-normal text-[#A4AFBD] '>
                Заявка оставлена для
              </p>
              <p className='font-medium text-medium '>
                {salonsData?.salon?.name}
              </p>
            </div>
          </div>
        </div>
        <div className=' max-w-[358px] w-full mx-auto flex flex-col    justify-between gap-4'>
          <div className='flex flex-col justify-between gap-2   '>
            {buttonState === "default" && (
              <button
                type='button'
                className={`px-4 py-2 w-full border rounded-lg text-white border-darkOrange bg-orange font-medium`}
                onClick={handleClick}
              >
                Позвонить
              </button>
            )}
            {buttonState === "loading" && (
              <button
                className={`px-4 py-2 w-full border rounded-lg text-white border-darkOrange bg-orange font-medium`}
              >
                <svg
                  className='animate-spin h-5 w-5 mx-auto text-white text-center'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                  ></path>
                </svg>
              </button>
            )}
            {buttonState === "number" && (
              <a
                href={`tel:${salonsData?.client?.phone}`}
                className={`px-4 py-2 w-full text-center border rounded-lg text-white border-darkOrange bg-orange font-medium`}
              >
                {salonsData?.client?.phone}
              </a>
            )}
          </div>
        </div>
        <span className='tableRowLine w-full'></span>
        <a href='https://navbat.tj' target='_blank' rel='noopener noreferrer'>
          <img src={banner} alt='owner' className='w-full' />
        </a>
      </div>
    </form>
  )
}

export default SalonsOwner