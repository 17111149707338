import { Checkbox } from "@mui/material"

export function MyCheckbox({
  name,
  value,
  onChange,
  className,
  inner,
  checked,
}) {
  return (
    <div className={className}>
      <Checkbox
        sx={{
          padding: 0,

          marginRight: 0.5,
          color: "gray.main",
          "&.Mui-checked": {
            color: "secondary.main",
          },
          "& .MuiSvgIcon-root": {
            fontSize: 18,
            borderRadius: 4,
          },
        }}
        value={value}
        onChange={onChange}
        name={name}
        checked={checked}
      />{" "}
      {inner}
    </div>
  )
}