import { useDeleteMasterMutation } from "pages/masters/slice"
import { DeleteModal } from "shared/ui/modal/DeleteModal"

export function RemoveMaster({ master, active, setActive, forceUpdate }) {
  const [deleteMasterQuery] = useDeleteMasterMutation()

  async function confirmRemove() {
    try {
      await deleteMasterQuery(master.id)
      forceUpdate?.()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <DeleteModal
      active={active}
      setActive={setActive}
      text='Вы действительно хотите удалить сотрудника?'
      onSubmit={confirmRemove}
    />
  )
}