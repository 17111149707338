import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import toast from "react-hot-toast"
import { currentUrl } from "../../url.js"

const accessToken = localStorage.getItem("accessToken")

type Service = {
  service_id: number
  master_id: number
  service_name: string
  duration: string
  master_name: string
  master_surname: string
  price: number
}

type Client = {
  id?: number
  name: string
  surname: string
  phone: string
}

type Master = {
  id: number
  name: string
  surname: string
}

type Status = {
  id: number
  name: string
}

type ShowClient = Client & {
  id: number
}

//type for all bookings
type IndexBookings = {
  id: number
  client: Client
  master: Master
  start: string
  end: string
  client_phone: string
  client_name: string
  client_surname: string
  salon_id: number
  status_id: number
}

//type for one booking
type ShowBooking = {
  id: number
  client: ShowClient
  status: Status
  start_date: string
  start_time: string
  salon_id: number
  comment: string
  service: Service
}

type BookingsState = {
  bookings: IndexBookings[]
  oneBooking: ShowBooking
  loading: boolean
  oneBookingLoading: boolean
  error: boolean
  oneBookingError: boolean
  editLoading: boolean
  editError: boolean
  postLoading: boolean
  postError: boolean
}
export const fetchOneBooking = createAsyncThunk<ShowBooking, number>(
  "booking/fetchOneBooking",
  async function (id) {
    try {
      const bookingResponse = await fetch(`${currentUrl}/master/table/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      const { booking: bookingData } = await bookingResponse.json()

      const [start_date, start_time] = bookingData?.start?.split(" ")

      const service: Service = {
        service_id: bookingData?.service?.id,
        master_id: bookingData?.master?.id,
        master_name: bookingData?.master?.name,
        master_surname: bookingData?.master?.surname,
        price: bookingData?.price,
        duration: bookingData?.service?.duration,
        service_name: bookingData?.service?.name,
      }

      const processedData: ShowBooking = {
        id: bookingData?.id,
        status: bookingData?.status,
        client: bookingData?.client,
        comment: bookingData?.comment || "",
        start_date: start_date,
        start_time: start_time,
        salon_id: bookingData?.salon_id,
        service: service,
      }
      return processedData
    } catch (err) {
      toast.error("Ошибка при загрузке данных", { position: "bottom-right" })
    }
  }
)

const initialState: BookingsState = {
  bookings: [],
  oneBooking: {
    id: 0,
    status: {
      id: 0,
      name: "",
    },
    service: {
      service_id: 0,
      master_id: 0,
      master_surname: "",
      master_name: "",
      price: 0,
      service_name: "",
      duration: "",
    },
    client: {
      name: "",
      surname: "",
      id: 0,
      phone: "",
    },
    comment: "",
    salon_id: 0,
    start_time: "",
    start_date: "",
  },
  oneBookingLoading: false,
  oneBookingError: false,
  loading: false,
  error: false,
  editLoading: false,
  editError: false,
  postLoading: false,
  postError: false,
}

const bookingSlice = createSlice({
  name: "bookingSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOneBooking.pending, (state) => {
        state.oneBookingLoading = true
        state.oneBookingError = false
      })
      .addCase(fetchOneBooking.fulfilled, (state, action) => {
        state.oneBooking = action.payload
        state.oneBookingLoading = false
      })
      .addCase(fetchOneBooking.rejected, (state) => {
        state.oneBookingLoading = false
        state.oneBookingError = true
      })
  },
})

export default bookingSlice.reducer