import { Skeleton } from "@mui/material"

export function PageSkeleton() {
  return (
    <>
      <div className='flex flex-col p-4'>
        {/* Avatar and its handler button */}
        <div className='flex items-end mb-5'>
          <Skeleton
            sx={{ marginRight: "30px" }}
            animation='wave'
            variant='rounded'
            width='150px'
            height='150px'
          />
          <Skeleton
            animation='wave'
            variant='rounded'
            width='100px'
            height='48px'
          />
        </div>

        {/* forms */}
        <div className='grid grid-cols-3 [&>span]:mb-4 mt-10 mb-5'>
          {[1, 2, 3, 4, 5, 6].map((n) => (
            <Skeleton
              key={n}
              animation='wave'
              variant='rounded'
              width='95%'
              height='60px'
            />
          ))}
        </div>

        {/* textarea */}
        <Skeleton
          animation='wave'
          variant='rounded'
          width='100%'
          height='150px'
        />

        {/* forms */}
        <div className='grid grid-cols-3 [&>span]:mb-4 mt-10 mb-10'>
          {[1, 2, 3].map((n) => (
            <Skeleton
              key={n}
              animation='wave'
              variant='rounded'
              width='95%'
              height='60px'
            />
          ))}
        </div>

        {/* submit button */}
        <Skeleton
          animation='wave'
          variant='rounded'
          width='100px'
          height='48px'
        />
      </div>
    </>
  )
}