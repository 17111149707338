import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getApiConfig } from "../../../shared/api"

const { BASE_URL, accessToken } = getApiConfig()

export const templatesApi = createApi({
  reducerPath: "templatesApi",
  tagTypes: "Templates",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`)
      }
      return headers
    },
  }),
  endpoints: (build) => ({
    getTemplates: build.query({
      query: (params) =>
        `/master/search/medical-templates?keyword=${params.search}`,
      providesTags: ({ data }) => {
        if (data) {
          return [
            ...data.map(({ id }) => ({ type: "Templates", id })),
            { type: "Templates", id: "LIST" },
          ]
        }
        return [{ type: "Templates", id: "LIST" }]
      },
    }),
    createTemplate: build.mutation({
      query: (body) => ({
        url: `/master/medical-templates`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Templates", id: "LIST" }],
    }),
    createSubTemplate: build.mutation({
      query: (body) => ({
        url: `/master/medical-tests`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Templates", id: "LIST" }],
    }),
    editTemplate: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/master/medical-templates/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "Templates", id: "LIST" }],
    }),
    editSubTemplate: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/master/medical-tests/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "Templates", id: "LIST" }],
    }),
    deleteTemplate: build.mutation({
      query: (id) => ({
        url: `/master/medical-templates/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Templates", id: "LIST" }],
    }),
  }),
})

export const {useGetTemplatesQuery, useCreateTemplateMutation, useEditTemplateMutation, useDeleteTemplateMutation, useCreateSubTemplateMutation, useEditSubTemplateMutation} = templatesApi

