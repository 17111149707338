import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  sliceData: {},
}

const editFormSlice = createSlice({
  name: "editFormSlice",
  initialState,
  reducers: {
    setSliceData(state, action) {
      state.sliceData = action.payload
    },
    setCurrentTemplates(state, action) {
      action.payload.forEach((updatedItem) => {
        // Находим соответствующий шаблон
        const template = state.sliceData.templates.find(
          (t) => t.id === updatedItem.medical_template_id
        )

        if (template) {
          // Находим и заменяем элемент в шаблоне
          template.items = template.items.map((item) => {
            if (item.id === updatedItem.id) {
              return updatedItem
            }
            return item
          })
        }
      })
    },
  },
})

export const {setSliceData, setCurrentTemplates} = editFormSlice.actions
export default editFormSlice.reducer
