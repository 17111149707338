import { reaction } from "mobx"
import { types } from "mobx-state-tree"

export const BasePageStore = types
  .model("BasePageStore", {
    currentPage: types.optional(types.integer, 1),
    rowsPerPage: types.optional(types.integer, 20),
    totalCount: types.optional(types.integer, 0),
    searchString: types.maybe(types.string),
  })
  .actions((self) => ({
    setCurrentPage: (page) => {
      self.currentPage = page
    },
    setTotalCount: (totalCount) => {
      self.totalCount = totalCount
    },
    setRowsPerPage: (count) => {
      self.rowsPerPage = count
    },
    setSearchString: (searchString) => {
      self.searchString = searchString
    },
    bulkUpdate: (bulkData) => {
      for (const key in bulkData) {
        if (!(key in self)) continue

        self[key] = bulkData[key]
      }
    },
  }))
  .views((self) => ({
    get numberOfPages() {
      return (self.totalCount / self.rowsPerPage).toFixed(0)
    },
  }))
  .actions((self) => ({
    afterCreate: () => {
      // const onChangeSearchDisposer = reaction(() => self.searchString, )
    },
  }))