import React from "react"
import { TemplateForms } from "../../../entities/laboratory/templates/TemplateForms"
import {
  useCreateSubTemplateMutation,
  useCreateTemplateMutation,
} from "../../../pages/laboratory/templates/templatesApi"
import { toast } from "react-hot-toast"

export const AddTemplate = ({ active, setActive }) => {
  const [addTemplate, { isLoading: templateLoading }] =
    useCreateTemplateMutation()
  const [createTests, { isLoading: testsLoading }] =
    useCreateSubTemplateMutation()
  const onSubmit = async (data) => {
    try {
      const res = await addTemplate({
        name: data.name,
        price: data.price,
      }).unwrap()
      if (res) {
        for (const item of data?.analyzes) {
          try {
            await createTests({
              name: item.name,
              norm: item.norm,
              unit: item.unit,
              result: 0,
              medical_template_id: res.data.id,
            }).unwrap()
          } catch (err) {
            console.log(err)
          }
        }
      }
      toast.success("Шаблон создан", { position: "bottom-right" })
      setActive(false)
    } catch (err) {
      console.log(err)
      toast.error("Ошибка при создании шаблона", { position: "bottom-right" })
      setActive(false)
    }
  }
  return (
    <TemplateForms
      isLoading={templateLoading || testsLoading}
      onSubmit={onSubmit}
      setActive={setActive}
      active={active}
    />
  )
}

