import React from "react"
import { Slide } from "@mui/material"
import Dialog from "@mui/material/Dialog"

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction='down' ref={ref} {...props}>
      {props.children}
    </Slide>
  )
})

const Modal = ({ open, setOpen, children }) => {
  return (
    <Dialog
      open={open}
      keepMounted
      TransitionComponent={Transition}
      onClose={() => setOpen(false)}
    >
      {children}
    </Dialog>
  )
}

export default Modal
